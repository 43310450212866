<template>
  <a class="create-account-close" @click="closeModal()">
    <svg height="15px" id="Layer_1" viewBox="0 0 512 512" width="15px">
      <path
          d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/>
    </svg>
  </a>
  <div class="onr-body__create-account-form">
    <div v-if="currentPage===0 && this.hasCode()" class="onr-body__create-form">
      <div class="onr-body__create-account-form--header">
        <h1>Step {{ currentPage + 1 }}/{{ numSteps() }}</h1>
      </div>
      <div class="input__container">
        <span class="input__label">First name</span>
        <input v-model="form.firstname" class="onr-body__create-form--full" type="text" name="first" required>
      </div>
      <div class="input__container">
        <span class="input__label">Last name</span>
        <input v-model="form.lastname" class="onr-body__create-form--full" type="text" name="last" required>
      </div>
      <div class="input__container">
        <span class="input__label">Email</span>
        <input v-model="form.email" class="onr-body__create-form--full" type="text" name="uname" @input="validateEmail" required>
      </div>
      <span v-if="!isValidEmail" class="input__error">
        Please enter a valid email address.
      </span>
      <div class="input__container">
        <span class="input__label">Password</span>
        <input v-model="form.password" class="onr-body__create-form--full" type="password" name="password" @input="validatePassword" required>
      </div>
      <span v-if="!isValidPassword" class="input__error">
        Password must be at least eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character.
      </span>
      <div class="input__container">
        <span class="input__label">Gender</span>
        <select v-model="form.gender" class="onr-body__create-form--full dropdown--gender">
          <option disabled value="">Please select one</option>
          <option value="m">Male</option>
          <option value="f">Female</option>
        </select>
      </div>
      <div class="input__container">
        <span class="input__label">Date of birth</span>
        <DatePicker
            class="onr-body__create-form--full"
            v-model="form.dob"
            :startingView="`year`"
            :typeable="true"
        />
      </div>
      <div><router-link style="text-decoration: none; color: inherit;" to="/nondiscriminatory-policy" target="_blank">Nondiscriminatory Policy</router-link></div>
      <button class="onr-body__create-form--next" :class="nextButtonClasses(0)" type="submit" @click="goToPage(1)">
        Next
      </button>
    </div>
    <div v-if="currentPage===1" class="onr-body__create-form">
      <div class="onr-body__create-account-form--header">
        <h1>Step {{ currentPage + 1 }}/{{ numSteps() }}</h1>
      </div>
      <div class="input__container">
        <span class="input__label">Nationality</span>
        <select v-model="form.country" class="onr-body__create-form--full dropdown--country" required>
          <option disabled value="">Please select one</option>
          <template v-for="(country, index) in countries" :key="index">
            <option :value="country.code">{{ country.name }}</option>
          </template>
        </select>
      </div>
      <div class="input__container">
        <span class="input__label">Residence</span>
        <select v-model="form.residence" class="onr-body__create-form--full dropdown--country" required>
          <option disabled value="">Please select one</option>
          <template v-for="(country, index) in countries" :key="index">
            <option :value="country.code">{{ country.name }}</option>
          </template>
        </select>
      </div>
      <div class="input__container">
        <span class="input__label">City</span>
        <input v-model="form.location" class="onr-body__create-form--full" type="text" name="location" required>
      </div>
      <div class="input__container">
        <span class="input__label">Phone</span>
        <input v-model="form.phone" class="onr-body__create-form--full" type="text" name="phone" required>
      </div>
      <div class="input__container">
        <span class="input__label">Occupation</span>
        <input v-model="form.occupation" class="onr-body__create-form--full" type="text" name="occupation" required>
      </div>
      <div class="input__container">
        <span class="input__label">Field of study</span>
        <select v-model="form.studyField" class="onr-body__create-form--full dropdown--subject">
          <option disabled value="">Please select one</option>
          <template v-for="(academic, index) in studyFields" :key="index">
            <option :value="academic.label" :checked="academic.checked">{{ academic.label }}</option>
          </template>
        </select>
      </div>
      <div class="input__container">
        <input v-model="form.other" v-show="form.studyField===`Other`" class="onr-body__create-form--full" type="text"
               name="other" required>
      </div>
      <div class="onr-body__create-form__actions">
        <button class="onr-body__create-form--next" type="submit" @click="goToPage(0)">Previous</button>
        <button class="onr-body__create-form--next" :class="nextButtonClasses(1)" type="submit" @click="goToPage(2)">
          Next
        </button>
      </div>
    </div>
    <div v-if="currentPage===2" class="onr-body__create-form">
      <div class="onr-body__create-account-form--header">
        <h1>Step {{ currentPage + 1 }}/{{ numSteps() }}</h1>
      </div>
      <div class="create-form__subjects-container">
        <span class="input__label">Subjects</span>
        <div class="subjects-div">
          <Checkbox
              class="subject-checkbox"
              v-for="(subject, index) in subjects.slice(0, 5)"
              :key="`subject-${subject.key}-${index}`"
              v-model="form.subjects"
              :name="subject.label"
              :value="subject.key"
              @checked="onSubjectsChecked(subject)"
              @unchecked="onSubjectsUnchecked(subject)"
              v-on:click= "check_one()"
          ></Checkbox>
        </div>
      </div>
      <div class="create-form__interests-container">
        <span class="input__label">Interests</span>
        <div class="interests-divs">
          <div class="interests-div">
            <Checkbox
                class="interest-checkbox"
                v-for="(interest, index) in interests.slice(0, 5)"
                :key="`interest-${interest.key}-${index}`"
                v-model="form.interests"
                :name="interest.label"
                :value="interest.key"
                @checked="onChecked(interest)"
                @unchecked="onUnchecked(interest)"
            ></Checkbox>
          </div>
          <div class="interests-div">
            <Checkbox
                class="interest-checkbox"
                v-for="(interest, index) in interests.slice(5, 10)"
                :key="`interest-${interest.key}-${index}`"
                v-model="form.interests"
                :name="interest.label"
                :value="interest.key"
                @checked="onChecked(interest)"
                @unchecked="onUnchecked(interest)"
            ></Checkbox>
          </div>
        </div>
      </div>
      <div class="onr-body__create-form__actions">
        <button class="onr-body__create-form--next" type="submit" @click="goToPage(1)">Previous</button>
        <button v-if="form.role===`Student`" class="onr-body__create-form--next" :class="nextButtonClasses(2)"
                type="submit" @click="goToPage(3)">Next
        </button>
        <button v-if="form.role===`Teacher`" class="onr-body__create-form--next" :class="nextButtonClasses(2)"
                type="submit" @click="goToPage(3)">Next
        </button>
      </div>
    </div>
    <div v-if="currentPage===3" class="onr-body__create-form create-form__availability">
      <div class="onr-body__create-account-form--header">
        <h1>Step {{ currentPage + 1 }}/{{ numSteps() }}</h1>
      </div>
      <div class="create-form__availability-container">
        <span class="input__label">Availability</span>
        <div v-if="form.role===`Teacher`">
          <Meetingselector @getMeetings="handleMeetings"/></div>
        <div v-if="form.role===`Student`">
          <Meetingselector2 @getMeetings="handleMeetings"/></div>

      </div>
      <!--
      <div class="create-form__availability-container">
        <h3 class="create-form__availability--heading">Availability</h3>
        <Checkbox
            class="availability-checkbox"
            v-for="(availability) in availabilities"
            :key="`availability-${availability.label}-${availability.startTime}`"
            v-model="availability.key"
            :name="`${availability.value} ${availability.startTime}-${availability.endTime}`"
            :value="availability.label"
            @checked="onAvailabilityChecked(availability)"
            @unchecked="onAvailabilityUnchecked(availability)"
        ></Checkbox>
      </div>-->
      <div class="onr-body__create-form__actions">
        <button class="onr-body__create-form--next" type="submit" @click="goToPage(2)">Previous
        </button>
        <button class="onr-body__create-form--next" :class="nextButtonClasses(3)" type="submit" @click="goToPage(4)">
          Submit
        </button>
      </div>
    </div>
    <div v-if="currentPage===4" class="onr-body__create-form">
      <h2>Welcome to THE DREAM family!</h2>
      <p>Your account is now created.<br>
        Please verify your email address.</p>

      <router-link v-if="form.role===`Teacher`" class="onr-body__find-teachers" to="/profile"
                   @click="closeModal()">My Page
      </router-link>
      <router-link v-if="form.role===`Student`" class="onr-body__find-teachers" to="/profile" @click="closeModal()">My
        Page
      </router-link>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Checkbox from '../../components/checkbox.vue';
import DatePicker from 'vue3-datepicker';
import createAccountForm from '../../data/accountCreationForm.json';
import countries from '@/data/countries.json';
import { mapState } from "vuex";
import Meetingselector from "@/components/meetingselector/meetingselector.vue";
import Meetingselector2 from "@/components/meetingselector/meetingselector2.vue";
import 'vue-meeting-selector/dist/style.css';


export default {
  name: 'CreateAccountForm',
  components: {
    Checkbox,
    DatePicker,
    Meetingselector,
    Meetingselector2,
  },
  data() {
    return {
      checked: false,
      isValidEmail: true,
      isValidPassword: true,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        gender: '',
        dob: new Date(1995, 0, 1),
        country: '',
        residence: '',
        location: '',
        phone: '',
        subjects: [],
        occupation: '',
        interests: [],
        availabilities: [],
        role: '',
        studyField: '',
        other: '',
      },

      currentPage: -1,
      subjects: [
        {
          key: 'sbs',
          label: 'SBS(English)',
          checked: false,
        },
        {
          key: 'lbl_coding',
          label: 'LBL(Coding)',
          checked: false,
        },
        {
          key: 'lbl_office',
          label: 'LBL(MS Office)',
          checked: false,
        },
        {
          key: 'han',
          label: 'Hanbud(Korean)',
          checked: false,
        }
      ],
      interests: [
        {
          key: 'cooking',
          label: 'Cooking',
          checked: false,
        },
        {
          key: 'kpop',
          label: 'K-Pop',
          checked: false,
        },
        {
          key: 'kdrama',
          label: 'Korean Drama',
          checked: false,
        },
        {
          key: 'sports',
          label: 'Sports',
          checked: false,
        },
        {
          key: 'travel',
          label: 'Travel',
          checked: false,
        },
        {
          key: 'reading',
          label: 'Reading',
          checked: false,
        },
        {
          key: 'art',
          label: 'Art',
          checked: false,
        },
        {
          key: 'dance',
          label: 'Dance',
          checked: false,
        },
        {
          key: 'fashion',
          label: 'Fashion',
          checked: false,
        },
        {
          key: 'photography',
          label: 'Photography',
          checked: false,
        }
      ],
      studyFields: [
        {
          key: 'accounting',
          label: 'Accounting',
          checked: false,
        },
        {
          key: 'economics',
          label: 'Economics',
          checked: false,
        },

        {
          key: 'education',
          label: 'Education',
          checked: false,
        },
        {
          key: 'engineering',
          label: 'Engineering',
          checked: false,
        },
        {
          key: 'finance',
          label: 'Finance',
          checked: false,
        },
        {
          key: 'language',
          label: 'Language',
          checked: false,
        },
        {
          key: 'law',
          label: 'Law',
          checked: false,
        },
        {
          key: 'marketing',
          label: 'Marketing',
          checked: false,
        },
        {
          key: 'math',
          label: 'Mathematics',
          checked: false,
        },
        {
          key: 'medical',
          label: 'Medical & Health',
          checked: false,
        },
        {
          key: 'music',
          label: 'Music',
          checked: false,
        },
        {
          key: 'pharmacy',
          label: 'Pharmacy',
          checked: false,
        },
        {
          key: 'religious',
          label: 'Religious Studies',
          checked: false,
        },
        {
          key: 'science',
          label: 'Science',
          checked: false,
        },
        {
          key: 'technology',
          label: 'Technology',
          checked: false,
        },
        {
          key: 'other',
          label: 'Other',
          checked: false,
        },

      ],
      progress: 0,
      availabilities: [
        {
          key: "1",
          value: "Monday",
          startTime: "8 AM",
          endTime: "9 AM",
        },
        {
          key: "2",
          value: "Monday",
          startTime: "9 AM",
          endTime: "10 AM",
        },
        {
          key: "3",
          value: "Monday",
          startTime: "10 AM",
          endTime: "11 AM",
        },
        {
          key: "4",
          value: "Monday",
          startTime: "11 AM",
          endTime: "12 PM",
        },
        {
          key: "5",
          value: "Monday",
          startTime: "12 PM",
          endTime: "1 PM",
        },
        {
          key: "6",
          value: "Monday",
          startTime: "1 PM",
          endTime: "2 PM",
        },
        {
          key: "7",
          value: "Monday",
          startTime: "2 PM",
          endTime: "3 PM",
        },
        {
          key: "8",
          value: "Monday",
          startTime: "3 PM",
          endTime: "4 PM",
        },
        {
          key: "1",
          value: "Monday",
          startTime: "4 AM",
          endTime: "5 PM",
        },
        {
          key: "2",
          value: "Monday",
          startTime: "5 PM",
          endTime: "6 PM",
        },
      ],
    };
  },

  mounted() {
    this.createAccountForm;
    this.countries;
    this.code;
    this.role;
    this.currentPage = 0;

  },
  computed: {
    ...mapState(["authStore"]),
    countries() {
      return countries;
    },
    createAccountForm() {
      return createAccountForm;
    },
    roles() {
      return createAccountForm[0].role;
    },

  },
  methods: {
    check_one(){
      if (this.form.role === 'Student') {
        this.form.subjects = [];
      }
    },
    numSteps() {
      const role = localStorage.getItem('role');
      return role === `Teacher` ? 3 : 4;
    },
    hasCode() {
      return !isEmpty(localStorage.getItem('code'));
    },
    handleMeetings(data) {

      this.form.availabilities = data;
    },
    onChecked(interest) {
      this.form.interests.push(interest);
    },
    onUnchecked(interest) {

      this.form.interests = this.form.interests.filter((item) => {
        return item.key !== interest.key;
      })
    },
    onAvailabilityChecked(availability) {
      this.form.availabilities.push(availability);
    },
    onAvailabilityUnchecked(availability) {
      this.form.availabilities = this.form.availabilities.filter((item) => {
        return item.key !== availability.key;
      })
    },
    onSubjectsChecked(subject) {
      this.form.subjects.push(subject)
    },
    onSubjectsUnchecked(subject) {
      this.form.subjects = this.form.subjects.filter((item) => {
        return item.key !== subject.key;
      })
    },
    nextButtonClasses(page) {
      return this.validFields(page) ? '' : 'button--disabled';
    },
    validFields(page) {
      const first = this.form.firstname && this.form.lastname && this.form.email && this.form.password && this.form.gender && this.form.dob && this.isValidPassword && this.isValidEmail;
      const second = this.form.occupation && this.form.studyField && this.form.country && this.form.residence && this.form.location && this.form.phone;
      const third = !isEmpty(this.subjects) && !isEmpty(this.form.interests);
      const fourth = !isEmpty(this.form.availabilities);
      if (page === 0) {
        return first;
      }
      if (page === 1) {
        return second;
      }
      if (page === 2) {
        return third;
      }
      return fourth;
    },
    goToPage(page) {

      this.currentPage = page;
      if (!this.form.role) {
        this.form.role = localStorage.getItem("role");
        //localStorage.removeItem("role");
        this.form.code = localStorage.getItem("code");
        //localStorage.removeItem("code");

      }

      if (page === 4) {
        if (this.form.studyField === "Other") {
          this.form.studyField = this.form.other;
        }
        this.form.subjects = [...new Set(this.form.subjects)];
        this.form.interests = [...new Set(this.form.interests)];
        this.$store.dispatch('authStore/register', this.form).then(() => {


        }).catch((error) => {
          //TODO error handling
          console.log(error);
          alert(error.message);
          this.goToPage(0);
        });

      }
    },
    closeModal() {
      window.location.reload();
      this.$emit('close');
    },
    imageuploaded() {
      console.log('...data');
    },
    onCreateAccount() {
      this.currentPage = 4;
    },
    // onStudentSelected(label) {
    // 	this.resetRoles();
    // 	const index = findIndex(this.roles, { label });
    // 	this.roles.splice(index, 1, {	label, value: true });

    // 	if (label === 'Student') this.currentPage = 1;
    // 	if (label === 'Teacher') this.currentPage = 2;
    // },
    onSubjectSelected(key) {
      this.resetSubjects();
      document.getElementById(`subject-panel-${key}`).classList.add('panel-box--selected');

      // ev.target.addClass('panel-box--selected').;
      // console.log('...panelCard:: ', panelCard)
    },
    resetSubjects() {
      document.getElementById('subject-panel-sbs').classList.remove('panel-box--selected');
      document.getElementById('subject-panel-lbl_coding').classList.remove('panel-box--selected');
      document.getElementById('subject-panel-lbl_office').classList.remove('panel-box--selected');
      document.getElementById('subject-panel-ko').classList.remove('panel-box--selected');
    },
    resetRoles() {
      this.roles.splice(0, 1, {label: 'Teacher', value: false});
      this.roles.splice(1, 1, {label: 'Student', value: false});	//	TODO-redo
    },
    validateEmail() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isValidEmail = regex.test(this.form.email);
    },
    validatePassword() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/;
      this.isValidPassword = regex.test(this.form.password);
    },
  },
  watch: {
    form: function onFormChange() {
      console.log('....data');
    },

  },
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';


.create-account {
  &-close {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
  }
}


.input {
  &__error {
    font-size: 11px;
    color: red;
    position: relative;
    width: 400px;
    text-align: left;
    top: -7px;

    @media only screen and (max-width: 500px) {
      width: 262px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: .5rem;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  &__label {
    color: $blue-1;
    margin-right: 1rem;
    font-size: 1.2em;
  }

  &__dropbtn {
    background-color: white;
    border: solid 1px $neutral-2;
    color: $neutral-2;
    font-size: 16px;
    cursor: pointer;
    width: 18em;
    height: 2.3em;
  }

  &__dropdown {
    position: relative;
    display: inline-block;
    margin-left: auto;
  }

  &__dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, .2);
    z-index: 1;
    width: 18em;
  }

  &__dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  &__dropdown-content a:hover {
    background-color: #f1f1f1
  }

  &__dropdown:hover .input__dropdown-content {
    display: block;
  }

  // &__dropdown:hover .input__dropbtn {
  // 	background-color: #3e8e41;
  // }


}

.panel {
  &-box {
    &--selected.onr-body__select-subject__panel--card {
      border: 1.8px solid $green-1;
      border-radius: 10px;
      box-shadow: 0 1px 3px rgba(83, 91, 96, 0.23);
    }

    &__top {
      .panel-box {
        &__subject {
          &-label {
            color: $black-1;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 800;
            vertical-align: sub;
          }
        }

        &__level {
          display: flex;

          &-label {
            color: $grey-1;
            font-size: 13px;
            font-weight: 500;
            margin: 7px 0 0 8px;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.create-form {
  &__subjects-container {
    display: flex;

    @media only screen and (max-width: 700px) {
      flex-direction: column;

      .subjects-div {
        display: unset;
      }
    }

    .subjects-div {
      display: flex;
      text-align: left;
      flex-direction: column;

      .subject-checkbox {
        margin-right: 0.5rem;
        white-space: nowrap;
      }
    }
  }
}

.create-form {
  &__interests-container {
    display: flex;

    @media only screen and (max-width: 700px) {
      flex-direction: column;

      .interests-divs {
        display: flex;

        .interests-div {
          flex-direction: column;
          display: flex;
          text-align: left;
        }
      }

    }

    .interests-divs {
      .interests-div {
        display: flex;
        text-align: left;

        .interest-checkbox {
          margin-right: 0.5rem;
          white-space: nowrap;
        }
      }
    }
  }
}

// 	&--heading {
// 		margin-bottom: 10px;
// 	}
// }

// .interest-checkbox {
// 	margin-bottom: 4px;

// 	.onr-checkbox__span {
// 		font-size: 15px;
// 	}
// }

.onr-body {
  &__progress {
    border: 1px solid $grey-2;
    border-radius: 20px;

    &-bar {
      background-color: $green-1;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      display: table-cell;
      height: 40px;
      overflow: hidden;
      vertical-align: middle;
      width: 25%;

      &--text {
        color: white;
      }
    }
  }

  &__select-subject {
    &__expand {
      margin: 15px 0 0 0;

      &-svg {
        cursor: pointer;
      }
    }

    &__panel {
      display: flex;
      margin: 0 200px;

      &--box {
        padding: 12px;
      }

      &--image {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 170px;
      }

      &--card {
        background-color: $neutral-1;
        border: 1px solid $neutral-2;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(83, 91, 96, 0.23);
        margin: 0 20px 20px 0;
        width: 30%;
      }

      &--box {
        .panel-box__top {
          display: unset;
        }

        span {
          margin: 0;
        }
      }
    }
  }

  &__create-account-form {
    align-items: center;

    &--header {
      margin-bottom: 1rem;
    }

    &--role-label, &--subject-label {
      margin-left: 5px;
    }

    &--subject-label {
      color: $black-1;
      font-weight: 500;
    }

    &--subject {
      .radio-option {
        &__radio-input {
          display: none;
        }
      }
    }
  }

  &__create-form {
    display: inline-grid;
    min-width: 415px;

    @media only screen and (max-width: 500px) {
      min-width: unset;
    }

    &--heading {
      color: $blue-1;
      margin-bottom: 1rem;
    }

    &.create-form__availability {
      @media only screen and (max-width: 500px) {
        min-width: 415px;

        .create-form__availability-container {
          text-align: center;
        }
      }
    }

    .create-form__availability {
      &--heading {
        text-align: center;
      }

      &-container {
        display: grid;
        text-align: left;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 500px) {
        flex-direction: column;

        :first-child {
          margin-bottom: 0;
        }
      }
    }

    &--sub {
      &:nth-child(2) {
        margin-left: 1rem;
      }

      display: flex;
      width: 400px;
    }

    &--half {
      width: 50%;
    }

    &--half, &--full {
      border: 1px solid $neutral-2;
      font-size: 14px;
      height: 2.3em;
      padding-left: 12px;
      margin-bottom: 10px;
      margin-right: 1rem;
    }

    &--full {
      width: 18em;
      margin-left: auto;
      margin-bottom: 0;
      order: 2;

      &.dropdown--gender,
      &.dropdown--country,
      &.dropdown--subject {
        width: 19.1em;
        height: 2.5em;
      }

      &.textarea {
        height: unset;
      }
    }

    &--next {
      background-color: $blue-1;
      border-radius: 25px;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 16px;
      height: 40px;
      margin-right: 0.5rem;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 200px;

      &.button--disabled {
        background-color: $grey-2;
        cursor: none;
        pointer-events: none;

        &:hover {
          background-color: $grey-2;
          border: none;
          cursor: unset;
        }
      }

      &:hover {
        background-color: $blue-1;
        border: solid 3px $blue-1;
        cursor: pointer;
      }
    }
  }
}

</style>