<template>
  <div class="onr-body__instructor">
    <div class="onr-body__instructor-panel" id="instructor-photo">
      <div class="onr-body__instructor-image">
        <img class="instructor-panel__img" :src="profileImage"/>
      </div>
      <div class="onr-body__instructor-header">
        <h2 class="onr-body__instructor-heading">{{
            currentInstructor.firstName + " " + currentInstructor.lastName
          }}</h2>
      </div>
      <div class="onr-body__instructor-content">
        <!-- <h3 class="onr-body__instructor-content--title-heading">course title:</h3> -->
        <!--<p class="onr-body__instructor-content--title-text">"{{ currentInstructor.quote }}"</p>-->
        <h4 class="onr-body__instructor-content--about-heading">ABOUT ME</h4>
        <p class="onr-body__instructor-content--about-text">
          Country: {{ getNameByCode(currentInstructor.country) }}<br>
          Residence: {{ getNameByCode(currentInstructor.residence) }} / {{ getNameByRefCode(currentInstructor.code) }}<br>
          Occupation: {{ currentInstructor.occupation }}<br>
          Interests: {{ currentInstructor.interests }}<br>
          Academic Fields: {{ currentInstructor.academicFields }}</p>

        <div class="onr-body__instructor-content--keywords">
          <div
              v-for="(keyword, index) in currentInstructor.keywords"
              :key="index"
              class="instructor-keyword"
          >
            {{ keyword.label }}: {{ keyword.count }}
          </div>
        </div>
      </div>
    </div>
    <div class="onr-body__instructor-panel--side">
      <h4 class="onr-body__instructor-details--heading">STUDENT DETAILS</h4>
      <div class="onr-body__instructor-details--content-container">
        <p class="onr-body__instructor-details--content-label">Subject</p>
        <p v-if="currentInstructor.sbs" class="onr-body__instructor-details--content-text">SBS (English)</p>
        <p v-if="currentInstructor.lbl_coding" class="onr-body__instructor-details--content-text">LBL(Coding)</p>
        <p v-if="currentInstructor.lbl_office" class="onr-body__instructor-details--content-text">LBL(MS Office)</p>
        <p v-if="currentInstructor.han" class="onr-body__instructor-details--content-text">Hanbud (Korean)</p>
      </div>
      <div class="onr-body__instructor-details--content-container instructor-details__availability">
        <p class="onr-body__instructor-details--content-label">Availability: </p>

        <div class="onr-body__instructor-details--availability">
          <template
              v-for="(day, index) in days"
              :key="index"
          >
            <a class="onr-body__instructor-details--availability-day" @click="onDaySelected(day)"
               :class="dayAvailabilityClasses(day)">
              <span class="instructor-details__availability-short">{{ day.short }}</span>
            </a>
          </template>
        </div>
        <div v-show="selectedDay" class="instructor-details__availability-time">
          <div class="instructor-details__availability-right">
            <template v-for="(availability, index) in getAvailableTimes(currentInstructor.availableTimes)"
                      v-bind:key="index">
              <a v-if="availability.day === selectedDay.label" class="availability--label"
                 @click="onTimeSelected(availability.time)" tabindex="0">
                <p class="instructor-details__availability-right--label">{{ availability.day }} -
                  {{ availability.time }} (ET)</p>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="onr-body__instructor-details--schedule">
        <button class="instructor-details__schedule" @click="scheduleCourse()">Schedule</button>
      </div>
    </div>
  </div>
</template>

<script>
//import instructors from '../../data/instructors.json';
import {auth} from '@/firebase.config';
import {ref, watchEffect} from "vue";
import {useRoute} from "vue-router";
import {getProfileImage} from "@/api/image";
import {createClass, getMyClass} from "@/api/classes";
import {getStudent} from "@/api/students";
import countries from "@/data/countries.json";
import {getUser} from "@/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Instructor',
  props: {
    data: {
      type: String,
    }
  },
  components: {
    // Checkbox,
  },

  setup() {
    const route = useRoute()
    const currentInstructor = ref(null);

    watchEffect(async () => {
      getStudent(route.params.id, auth.currentUser.uid).then((resp) => {

        currentInstructor.value = resp[0];

      }).catch((err) => {
        console.log('..err: ', err);
      });
    });
    return {
      currentInstructor
    }
  },
  data() {
    return {
      selectedDay: '',
      selectedTime: '',
      selectedTeacher: '',
      selectedSubject: '',
      profileImage: '',
      days: [
        {
          label: 'Sun',
          short: 'S',
          value: false,
        },
        {
          label: 'Mon',
          short: 'M',
          value: false,
        },
        {
          label: 'Tue',
          short: 'T',
          value: false,
        },
        {
          label: 'Wed',
          short: 'W',
          value: false,
        },
        {
          label: 'Thu',
          short: 'T',
          value: false,
        },
        {
          label: 'Fri',
          short: 'F',
          value: false,
        },
        {
          label: 'Sat',
          short: 'S',
          value: false,
        },

      ],
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});

    getProfileImage(auth.currentUser.uid).then((response) => {
      if (response !== '') {
        this.profileImage = response;
      } else {//temp
        this.profileImage = 'https://firebasestorage.googleapis.com/v0/b/mystic-tempo-383023.appspot.com/o/public%2Fprofile_4945750.png?alt=media';
        //console.log(this.profileImage)
      }
    }).catch((error) => {
      console.log(error);

    });
    this.selectedSubject = this.currentInstructor.sbs ? 'SBS' : this.currentInstructor.lbl_coding ? 'LBL(Coding)' : this.currentInstructor.lbl_office ? 'LBL(MS Office)' : this.currentInstructor.han ? 'Hanbud' : '';

  },
  computed: {
    /*
        instructors() {
          return this.instructor;
        },*/
    /*
    currentInstructor() {
      const currentId = this.$route.params.id;
      console.log('..currentId: ', currentId);

      const currentInstructor = this.instructors.find((instructor) => {
        return instructor.tid.toString() === currentId;
      });
      console.log(this.instructor);
      const currentInstructor = this.instructor;
      console.log('..currentInstructor: ', currentInstructor);
      return currentInstructor;
    },
    currentInstructorPhotoPath() {
      return `.${this.currentInstructor.photo}`;
    },*/
    remappedDays() {
      // const availableDays = this.currentInstructor.availability;

      // const mappedDays = map(availableDays, (availableDay) => {
      // 	return filter(this.days, (day) => {
      // 		if (day.label === availableDay.day) {
      // 			return {
      // 				...day,
      // 				value: true,
      // 			}
      // 		}
      // 	});
      // });
      // console.log('..mappedDays: ', mappedDays);
      return '';
    },
  },
  methods: {
    getNameByRefCode(code) {
      let country = '';
      if (code.toUpperCase().startsWith("IRAQ")) {
        country = "Iraq";
      } else if (code.toUpperCase().startsWith("BETH")) {
        country = "Bethlehem";
      } else if (code.toUpperCase().startsWith("ESKI")) {
        country = "Eskisehir";
      } else if (code.toUpperCase().startsWith("ISTA")) {
        country = "Istanbul";
      } else if (code.toUpperCase().startsWith("ANTI")) {
        country = "Antioch";
      } else if (code.toUpperCase().startsWith("JORD")) {
        country = "Jordan";
      } else if (code.toUpperCase().startsWith("GTC")) {
        country = "GTC";
      } else if (code.toUpperCase().startsWith("LEBA")) {
        country = "Lebanon";
      } else {
        country = "";
      }
      return country ? country : null;
    },
    getNameByCode(countryCode) {
      const country = countries.find(country => country.code === countryCode);
      return country ? country.name : null;
    },
    getAvailableTimes(availableTimes) {
      function isDaylightSavingTime() {
        const now = new Date();

        // Get the timezone offset in minutes (difference between UTC and local time)
        const jan = new Date(now.getFullYear(), 0, 1).getTimezoneOffset(); // January 1st (usually standard time)
        const jul = new Date(now.getFullYear(), 6, 1).getTimezoneOffset(); // July 1st (usually daylight saving time)

        // Compare January and July offsets to current offset
        const currentOffset = now.getTimezoneOffset();

        // If the current offset is less than January's, DST is in effect
        return currentOffset < Math.max(jan, jul);
      }

      const dayTimePairs = JSON.parse(availableTimes).map(pair => {
        const [day, time] = pair.split(" ");
        const availabilityDate = new Date(`2023-01-01T${time}:00`);
        // TODO daylight saving time
        // Deduct 300 minutes from the time part
        if (this.currentInstructor.residence === 'IL' || this.currentInstructor.residence === 'PS' || this.currentInstructor.residence === 'LB') {
          if (isDaylightSavingTime()) {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 360);
          } else {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 420);
          }
        } else {
          if (isDaylightSavingTime()) {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 420);
          } else {
            availabilityDate.setMinutes(availabilityDate.getMinutes() - 480);
          }
        }

        // Format the result as "Day HH:mm"
        //const adjustedAvailability = {day: day, time: availabilityDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })};
        //const adjustedAvailability = {day: day, time: `${availabilityDate.getHours()}:00`}
        const adjustedAvailability = {
          day: day, time: availabilityDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
        }
        return adjustedAvailability;
      });
      // Define the order of days
      const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

// Sorting the array by day and time
      dayTimePairs.sort((a, b) => {
        // Compare the days first
        const dayComparison = dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);

        if (dayComparison !== 0) {
          return dayComparison; // Sort by day if they are different
        }

        // If days are the same, compare the times
        const timeA = new Date(`1970/01/01 ${a.time}`);
        const timeB = new Date(`1970/01/01 ${b.time}`);

        return timeA - timeB; // Sort by time if days are the same
      });

      return dayTimePairs;
    },
    scheduleCourse() {
      // TODO check dup class
      if (this.selectedDay === '') {
        alert('Please select a day.');
        return;
      }
      if (this.selectedTime === '') {
        alert('Please select a time.');
        return;
      }
      if (auth.currentUser.uid === this.currentInstructor.uid) {
        alert('You\'re not a teacher.');
        return;
      }
      getMyClass("Teacher").then((resp) => {
            if (resp) {
              alert("You already have scheduled a class.");

            } else {
              getUser().then((resp) => {
                this.user = resp;

                this.selectedSubject = this.currentInstructor.sbs ? 'SBS' : this.currentInstructor.lbl_coding ? 'LBL(Coding)' : this.currentInstructor.lbl_office ? 'LBL(MS Office)' : this.currentInstructor.han ? 'Hanbud' : '';

                createClass(auth.currentUser.uid, this.user.firstName + " " + this.user.lastName, this.currentInstructor.uid, this.currentInstructor.firstName + " " + this.currentInstructor.lastName,
                    this.selectedSubject, this.selectedDay.label + " " + this.selectedTime).then(() => {

                  alert('Your class is scheduled.');
                  this.$router.push({
                    name: 'StudentPage',
                  });
                }).catch((error) => {
                  console.log('..error: ', error);
                });
              }).catch((err) => {
                console.log('err: ', err);
              });
            }
          }
      ).catch((err) => {
        console.log('err: ', err);
      });
//alert("You have scheduled a class.");
// alert("I am sorry, you cannot currently schedule a class.");

// call api

    },
    onDaySelected(val) {
      this.selectedDay = val;
    }
    ,
    onTimeSelected(val) {
      this.selectedTime = val;
    }
    ,
    dayAvailabilityClasses(eachDay) {
      return this.selectedDay.label === eachDay.label ? `selected selected-availability--${this.selectedDay.short}` : '';
    }
    ,

  }
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

@media only screen and (max-width: 1000px) {
  .onr-body__instructor {
    flex-direction: column;

    .onr-body__instructor-panel {
      width: 100%;
      margin-bottom: 0;
    }

    .onr-body__instructor-panel--side {
      padding: 30px 0;
      width: 100%;
      margin-left: 0;
    }
  }
}

.onr-body {
  &__instructor {
    background-color: $beige-1;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 6em 0 0 0;

    &-details {
      &--availability {
        display: flex;
        justify-content: center;

        &-day {
          background-color: white;
          border: 1px solid $neutral-2;
          border-radius: 5px;
          color: $grey-1;
          cursor: pointer;
          height: 32px;
          margin-right: 5px;
          width: 30px;

          &.selected {
            background-color: $green-1;
            border: 1px solid $green-1;
            color: white;
          }

          &:hover {
            background-color: $green-1;
            border: 1px solid $green-1;
            color: white;
          }

          .instructor-details__availability {
            &-short {
              font-weight: 500;
              vertical-align: middle;
            }
          }
        }
      }

      &--heading {
        margin: 0 0 30px 0;
      }

      &--schedule {
        margin: 40px;

        .instructor-details__schedule {
          background-color: $green-1;
          border-radius: 25px;
          border: none;
          color: white;
          cursor: pointer;
          font-size: 20px;
          font-weight: 600;
          padding: 15px 40px;
          width: 100%;

          &:hover {
            background-color: $green-2;
          }
        }
      }

      &--content {
        &-container {
          &.instructor-details__availability {
            display: unset;
          }

          .instructor-details__availability {
            &-time {
              display: flex !important;
            }

            &-left {
              &--label {
                font-size: 18px;
                font-weight: 500;
                margin-left: 24px;
                text-align: left;
              }
            }

            &-right {
              margin: 5px 30px 0 auto !important;
              order: 2;

              .availability--label {
                display: block;

                &:focus {
                  border: solid 1px black;
                  border-radius: 25px;
                  margin-bottom: 0.5rem;
                  outline: none;

                }

                &:hover {
                  border: solid 1px black;
                  border-radius: 25px;
                  margin-bottom: 0.5rem;
                }


              }

              &--label {
                margin: 0 !important;
                padding: 0.3rem 0.7rem;
                font-weight: 500;

              }
            }
          }
        }

        &-label {
          color: $black-1;
          font-size: 14px;
          font-weight: 500;
          margin: 0 0 10px;
          text-transform: uppercase;
        }


        &-text {
          font-size: 22px;
          font-weight: 500;
          margin: 0 0 50px;
          text-transform: uppercase;
        }
      }
    }

    &-header {

    }

    &-content {
      &--title {
        &-heading {
          margin: 0;
        }

        &-text {
          // border-left: 5px solid $green-1;
          // border-right: 5px solid $green-1;
          font-size: 20px;
          font-style: italic;
          margin: auto;
          width: 70%;
        }
      }

      &--about {
        &-heading {
          margin: 50px 0 0 0;
        }

        &-text {
          font-size: 18px;
          margin: 1rem 3rem 7rem;
        }
      }

      &--keywords {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 50px;

        .instructor-keyword {
          background-color: $grey-1;
          color: white;
          border-radius: 25px;
          padding: 5px 15px;
          margin: 3px;
        }
      }
    }

    &-image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      margin-top: 105.76px;

      .instructor-panel {
        &__img {
          border-radius: 50%;
          height: 15em;
        }
      }
    }

    &-panel, &-panel--side {
      background-color: $neutral-1;
      box-shadow: 0 1px 3px rgba(83, 91, 96, 0.23);
      margin: 50px 0;
      width: 50%;

      &--side {
        padding: 30px;
        margin-left: 20px;
        width: 18%;
      }
    }

  }
}

</style>