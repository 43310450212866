<template>
  <div class="onr-referral">
    <div class="onr-referral__container">
      <h4 class="referral-code__label">Referral code</h4>
      <input
          class="onr-referral__text-field"
          ref="referralCode"
          v-model="code"
          field-id="referral-code"
          input-type="single-line"
          placeholder="Enter referral code"
      />
      <button class="onr-referral__button" @click="onCodeSubmit()">Submit</button>
    </div>
  </div>
</template>

<script>
import { some } from 'lodash';
//import sampleReferralCode from '../../data/sampleReferralCode.json';
 import {referral} from "@/api";

export default {
  name: 'ReferralCodePage',
  components: {},
  props: {},
  data() {
    return {
      code: '',
    }
  },
  computed: {
    /*
    codes() {
      return sampleReferralCode;
    },*/
  },
  mounted() {

  },
  methods: {
    validateCode() {
      return some(this.codes, ['code', this.code]);
    },
    onCodeSubmit() {
      // TODO: set in store -- authenticated
      referral(this.code).then(res => {

         if (res.code !== undefined && res.code.toUpperCase() === this.code.toUpperCase()) {
           localStorage.setItem('code', this.code);
           localStorage.setItem('role', res.role);
           this.verified();
         }
       }).catch(err => {
         console.log(err);
         alert('Invalid referral code');
       });
    },
    verified() {
      this.$emit('verify');
    },
  }
}
</script>

<style lang="scss">
@import '../../utils/_colors.scss';

.onr-referral {
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 10%;

    .referral-code {
      &__label {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__text-field {
    border: 1px solid $grey-4;
    height: 35px;
    padding-left: 0.7rem;
    margin-bottom: 2rem;
    width: 250px;

  }

  &__button {
    background-color: $blue-1;
    border-radius: 25px;
    color: white;
    font-size: 15px;
    border: solid 3px $blue-1;
    font-weight: 400;
    padding: 10px 20px;

    &:hover {
      background-color: $green-1;
      border: solid 3px $green-1;
      cursor: pointer;
    }
  }
}
</style>