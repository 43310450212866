import { createApp } from 'vue'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import CarbonVue3 from '@carbon/vue';
import App from './App.vue';
import router from './components/router';
import store from './store';
import {auth} from './firebase.config';
import * as Vue from "vue-plugin-load-script";
let app;

const vuetify = createVuetify({
    components,
    directives,
})


Vue.loadScript("https://maps.googleapis.com/maps/api/js")
const unsubscribe = auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(store).use(CarbonVue3).use(router).use(vuetify).mount('#app');
    }
    unsubscribe();
});


